export default [
  {
    header: 'Hồ sơ',
  },
  {
    title: 'Tạo mới',
    route: 'document-create',
    icon: 'FeatherIcon',
  },
  {
    title: 'Danh sách',
    route: 'document-list',
    icon: 'ListIcon',
  },
  {
    title: 'Thuộc tính',
    route: 'document-attribute',
    icon: 'CrosshairIcon',
  },
]
